import React from "react";
import ReadController from "./ReadController";

function Controller(props) {
  return (
    <div>
      <ReadController />
    </div>
  );
}
export default Controller;
