import axios from "axios";

const protocol =
  process.env.REACT_APP_NODE_ENV === "develop" ? "http" : "https";
const userApiService = `${protocol}://${process.env.REACT_APP_BACKEND_URL}`;
let returnValue;
const token = localStorage.getItem("refreshToken");
const config = { headers: { Authorization: `Bearer ${token}` } };

class APIServices {
  async login(email, password) {
    console.log("userApiService", userApiService);
    try {
      let newUserData = {
        email: email,
        password: password,
      };
      const serviceData = {
        method: "POST",
        url: userApiService + "/user/login",
        data: newUserData,
        withCredentials: true,
      };
      await axios(serviceData).then((response) => {
        localStorage.setItem("refreshToken", response.data.refreshToken);
        returnValue = response;
      }).catch(err => console.log("login err", err));
    } catch (error) {
      return error.response;
    }
    return returnValue;
  }
  async recognizeRaspi(raspIp, odaName) {
    // console.log(raspIp);
    // console.log(odaName);
    try {
      let newUserData = {
        odaName: odaName,
      };
      // console.log(newUserData)
      const serviceData = {
        method: "POST",
        url: "https://" + raspIp[1].localIp + ":8080/odaName",
        data: newUserData,
      };
      return await axios(serviceData);
    } catch (error) {
      return error.response;
    }
  }
  async connectSocket(odaName) {
    try {
      let newUserData = {
        odaName: odaName,
      };
      const serviceData = {
        method: "POST",
        url: "http://192.168.1.105:8080/connectSocket",
        data: newUserData,
      };
      return await axios(serviceData);
    } catch (error) {
      return error.response;
    }
  }

  async register(firstName, secondName, email, password, confPassword) {
    try {
      let newUserData = {
        firstName: firstName,
        secondName: secondName,
        email: email,
        password: password,
        confPassword: confPassword,
      };
      const serviceData = {
        method: "POST",
        url: userApiService + "/user/register",
        data: newUserData,
      };
      return await axios(serviceData);
    } catch (error) {
      return error.response;
    }
  }
  async getUsers(email) {
    const serviceData = {
      method: "GET",
      url: userApiService + "/user/getUsers",
      data: email,
    };
    return await axios(serviceData, config);
  }
  async logout() {
    const serviceData = {
      method: "DELETE",
      url: userApiService + "/user/logout",
    };
    localStorage.removeItem("refreshToken");
    return await axios(serviceData);
  }
  async refreshToken() {
    try {
      const serviceData = {
        method: "GET",
        url: userApiService + "/token/refreshToken",
      };
      returnValue = await axios(serviceData);
    } catch (error) {
      return error.response;
    }
    return returnValue;
  }
  async newCustomer(email) {
    try {
      let newUserData = {
        email: email,
      };
      const serviceData = {
        method: "POST",
        url: userApiService + "/customer/newCustomer",
        data: newUserData,
      };
      returnValue = await axios(serviceData);
    } catch (error) {
      return error.response;
    }
    return returnValue;
  }
  async newUser(email, odaName, odaNick) {
    let newUserData = {
      email: email,
      odaName: odaName,
      odaNick: odaNick,
    };
    const serviceData = {
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      url: userApiService + "/user/newuser",
      data: newUserData,
    };
    return await axios(serviceData);
  }
  async newOda(odaName) {
    const serviceData = {
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      url: userApiService + "/odaIdentify/newOda",
      data: odaName,
    };
    return await axios(serviceData);
  }
  async isAvailableOdaNick(userData) {
    let odaNickData = {
      odaNick: userData,
    };
    const serviceData = {
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      url: userApiService + "/odaIdentify/isAvailableOdaNick",
      data: odaNickData,
    };
    return await axios(serviceData);
  }

  async myOdas(email) {
    let getEmail = {
      email: email,
    };
    const serviceData = {
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      url: userApiService + "/user/myOdas",
      data: getEmail,
    };
    return await axios(serviceData);
  }

  async getAllCoreographies() {
    const serviceData = userApiService + "/choreography/allcor";
    return await axios.get(serviceData, config);
  }
  async getMyCoreographies(ownerId) {
    let userIdData = {
      ownerId: ownerId,
    };
    const serviceData = {
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      url: userApiService + "/choreography/mycor",
      data: userIdData,
    };
    return await axios(serviceData);
  }
  async getHitsCoreographies() {
    let userData = {
      limit: 2,
    };
    const serviceData = {
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      url: userApiService + "/choreography/hits",
      data: userData,
    };
    return await axios(serviceData);
  }
  async isUserAvailable(email) {
    const userEmail = {
      email: email,
    };
    const serviceData = {
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      url: userApiService + "/user/isUserAvailable",
      data: userEmail,
    };
    return await axios(serviceData);
  }
  async createCoreography(name, trackName, file, trackId, ownerId, isShared) {
    const createCorData = {
      name,
      trackName,
      file,
      trackId,
      ownerId,
      isShared,
      version: "v.1.0",
    };
    const serviceData = {
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      url: userApiService + "/choreography/create",
      data: createCorData,
    };
    return await axios(serviceData);
  }
  async myOdaOnlyEmail({ email }) {
    const serviceData = {
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      url: userApiService + "/user/myOdaOnlyEmail",
      data: { email },
    };
    return await axios(serviceData);
  }
  async liveTry({ odaIP, cor }) {
    var form = new FormData();
    form.append("u", "59");
    form.append("d", cor);
    const serviceData = {
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      url: `http://${odaIP}:9090/set_dmx`,
      data: form,
      headers: { "Content-Type": "multipart/form-data" },
    };

    return await axios(serviceData);
  }
  async getMyEditingCor(corId) {
    let userIdData = {
      corId,
    };
    const serviceData = {
      method: "POST",
      url: userApiService + "/choreography/getEditCor",
      data: userIdData,
    };
    return await axios(serviceData);
  }

  async getMyEditableCors(corId) {
    const serviceData = {
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      url: userApiService + "/choreography/getEditableCors",
    };
    return await axios(serviceData);
  }

  async overwriteCor({ corId, file, name }) {
    let userIdData = {
      corId,
      file,
      name,
    };
    const serviceData = {
      method: "POST",
      url: userApiService + "/choreography/overwrite",
      data: userIdData,
    };
    return await axios(serviceData);
  }

  async sendReviews({ review, rating, corId }) {
    let ratings = {
      review,
      rating,
      corId,
    };
    const serviceData = {
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      url: userApiService + "/choreography/addReview",
      data: ratings,
    };
    return await axios(serviceData);
  }
  async readCorReviews({ corId }) {
    let ratings = {
      corId,
    };
    const serviceData = {
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      url: userApiService + "/choreography/readCorReviews",
      data: ratings,
    };
    return await axios(serviceData);
  }
  async createNewList({ name, corId }) {
    let data = {
      name,
      corId,
    };
    const serviceData = {
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      url: userApiService + "/choreographyList/create",
      data,
    };
    return await axios(serviceData);
  }
  async getUserCorListAll() {
    const serviceData = {
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      url: userApiService + "/choreographyList/all",
    };
    return await axios(serviceData);
  }
  async addNewSongToList({ newSong, corListId }) {
    const serviceData = {
      headers: { Authorization: `Bearer ${token}` },
      method: "POST",
      url: userApiService + "/choreographyList/addNewSong",
      data: { newSong, corListId },
    };
    return await axios(serviceData);
  }

  async livePartCreateCategory({ name }) {
    const serviceData = {
      headers: { Authorization: `Bearer ${token}` },
      method: "POST",
      url: userApiService + "/live-party/create-category",
      data: { name },
    };
    return await axios(serviceData);
  }

  async getLivePartAllCategory() {
    const serviceData = {
      headers: { Authorization: `Bearer ${token}` },
      method: "POST",
      url: userApiService + "/live-party/all-category",
    };
    return await axios(serviceData);
  }

  async livePartAddFileToCategory({ categoryId, name, color, file }) {
    const serviceData = {
      headers: { Authorization: `Bearer ${token}` },
      method: "POST",
      url: userApiService + "/live-party/add-file-to-category",
      data: { categoryId, name, color, file },
    };
    return await axios(serviceData);
  }
}

export default APIServices;
